.loading__spinner {
  position: absolute;
  z-index: 1;
  width: 1.8rem;
}

.loading__spinner {
  width: 1.8rem;
  display: inline-block;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: rgb(var(--color-foreground));
  animation: dash 1.4s ease-in-out infinite;
}

@media screen and (forced-colors: active) {
  .path {
    stroke: CanvasText;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 280;
  }
  50% {
    stroke-dashoffset: 75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(450deg);
  }
}

.loading__spinner:not(.hidden) + .cart-item__price-wrapper,
.loading__spinner:not(.hidden) ~ cart-remove-button {
  opacity: 50%;
}

.loading__spinner:not(.hidden) ~ cart-remove-button {
  pointer-events: none;
  cursor: default;
}
